import React from "react";
import { CheckSquareFill, App } from "react-bootstrap-icons";

const CheckField = ({ day, freeDay, inputName, onHandleUpdate }) => {
  const tdStyle = { cursor: "pointer", textAlign: "center" };
  if (freeDay) return <td></td>;
  if (day[inputName]) {
    return (
      <td onClick={() => onHandleUpdate(inputName)} style={tdStyle}>
        <div>
          <CheckSquareFill />
        </div>
      </td>
    );
  }
  return (
    <td onClick={() => onHandleUpdate(inputName)} style={tdStyle}>
      <div className="hide-on-print">
        <App />
      </div>
    </td>
  );
};

export default CheckField;
