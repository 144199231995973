import React from "react";
import { Table } from "react-bootstrap";
import TimeField from "./TimeField";
import CheckField from "./CheckField";
import TotalTable from "./TotalTable";

const DisplayTable = ({ days, updateDays }) => {
  const [dates, setDates] = React.useState(() => days);
  React.useEffect(() => {
    setDates(days);
  }, [days]);

  React.useEffect(() => {
    updateDays(dates);
    // eslint-disable-next-line
  }, [dates]);

  const updateInputTime = (value, day, inputName) => {
    const tempDates = dates.map((selectedDate) => {
      if (selectedDate === day) {
        const selectedTime = {
          hour: value.split(":")[0],
          minute: value.split(":")[1],
        };
        return {
          ...selectedDate,
          [inputName]: selectedTime,
        };
      }
      return selectedDate;
    });
    setDates(tempDates);
  };

  const updateInputCheck = (inputName, day) => {
    const reverseField = inputName === "vacation" ? "sickLeave" : "vacation";
    const tempDates = dates.map((selectedDate) => {
      if (selectedDate === day) {
        return {
          ...selectedDate,
          [inputName]: !day[inputName],
          [reverseField]: day[inputName] ? !day[inputName] : false,
        };
      }
      return selectedDate;
    });
    setDates(tempDates);
  };

  if (dates.length <= 0) return null;
  return (
    <Table bordered hover>
      <thead>
        <tr style={{ backgroundColor: "rgba(121,130,138,0.10)" }}>
          <th>Datum</th>
          <th>Ura začetka</th>
          <th>Ura konca</th>
          <th>Malica začetek</th>
          <th>Malica konec</th>
          <th>Dopust</th>
          <th>Bolniška</th>
        </tr>
      </thead>
      <tbody>
        {dates.map((day) => {
          const isFreeDay = day.holiday || day.weekend;

          return (
            <tr className={isFreeDay ? "table-warning" : ""} key={day.date}>
              <td>{new Date(day.date).toLocaleDateString("sl-SI")}</td>
              <td>
                <TimeField
                  freeDay={isFreeDay}
                  day={day}
                  inputName="workStart"
                  onHandleUpdate={(updatedValue, inputName) => {
                    updateInputTime(updatedValue, day, inputName);
                  }}
                />
              </td>
              <td>
                <TimeField
                  freeDay={isFreeDay}
                  day={day}
                  inputName="workEnd"
                  onHandleUpdate={(updatedValue, inputName) => {
                    updateInputTime(updatedValue, day, inputName);
                  }}
                />
              </td>
              <td>
                <TimeField
                  freeDay={isFreeDay}
                  day={day}
                  inputName="lunchStart"
                  onHandleUpdate={(updatedValue, inputName) => {
                    updateInputTime(updatedValue, day, inputName);
                  }}
                />
              </td>
              <td>
                <TimeField
                  freeDay={isFreeDay}
                  day={day}
                  inputName="lunchEnd"
                  onHandleUpdate={(updatedValue, inputName) => {
                    updateInputTime(updatedValue, day, inputName);
                  }}
                />
              </td>

              <CheckField
                day={day}
                inputName="vacation"
                freeDay={isFreeDay}
                onHandleUpdate={(inputName) => {
                  updateInputCheck(inputName, day);
                }}
              />

              <CheckField
                day={day}
                inputName="sickLeave"
                freeDay={isFreeDay}
                onHandleUpdate={(inputName) => {
                  updateInputCheck(inputName, day);
                }}
              />
            </tr>
          );
        })}
        <TotalTable dates={dates} />
      </tbody>
    </Table>
  );
};

export default DisplayTable;
