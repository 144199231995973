import React from "react";
import { Form } from "react-bootstrap";

import { formatHourMinuteString } from "../helpers";

const TimeField = ({ freeDay, day, inputName, onHandleUpdate }) => {
  if (freeDay) return null;
  return (
    <Form.Control
      type="time"
      value={`${formatHourMinuteString(day[inputName])}`}
      size="sm"
      step="600"
      onChange={(e) => {
        onHandleUpdate(e.target.value, inputName);
      }}
    />
  );
};

export default TimeField;
