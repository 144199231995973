import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import DisplayTable from "./components/DisplayTable";
import SummaryTable from "./components/SummaryTable";
import EmployeeInfo from "./components/EmployeeInfo";

import { months, generateYears, generateDays } from "./helpers";

const employee = [
  { value: "Dejan Štrancar", label: "Dejan Štrancar" },
  { value: "Tanja Štrancar", label: "Tanja Štrancar" },
  { value: "Nejc Ukmar", label: "Nejc Ukmar" },
];

const currentDate = new Date();

const years = generateYears(currentDate.getFullYear());

const App = () => {
  const [selectedEmployee, setSelectedEmployee] = React.useState(employee[0]);
  const [selectedMonth, setSelectedMonth] = React.useState(
    months.filter((month) => month.value === currentDate.getMonth())[0]
  );
  const [selectedYear, setSelectedYear] = React.useState(
    years.filter((year) => year.value === currentDate.getFullYear())[0]
  );
  const [days, setDays] = React.useState([]);

  React.useEffect(() => {
    setDays([]);
  }, [selectedEmployee, selectedMonth, selectedYear]);

  const generateTable = async () => {
    const tempDays = await generateDays(
      selectedYear.value,
      selectedMonth.value
    );
    setDays(tempDays);
  };

  return (
    <Container style={{ padding: "50px 0 0 0" }}>
      <Row style={{ margin: "0 0 25px 0" }} className="hide-on-print">
        <Col>
          <h1>APTO lista prisotnosti</h1>
        </Col>
      </Row>
      <Row className="hide-on-print">
        <Col>
          <Form.Label>Delavec</Form.Label>
          <Select
            options={employee}
            value={selectedEmployee}
            onChange={(selected) => setSelectedEmployee(selected)}
          />
        </Col>
        <Col>
          <Form.Label>Mesec</Form.Label>
          <Select
            options={months}
            value={selectedMonth}
            onChange={(selected) => setSelectedMonth(selected)}
          />
        </Col>
        <Col>
          <Form.Label>Leto</Form.Label>
          <Select
            options={years}
            value={selectedYear}
            onChange={(selected) => setSelectedYear(selected)}
          />
        </Col>
      </Row>
      <Row className="hide-on-print">
        <Col style={{ padding: "25px 0" }}>
          <Button
            style={{ margin: "0 auto", display: "block" }}
            onClick={generateTable}
          >
            Generiraj tabelo
          </Button>
        </Col>
      </Row>

      <EmployeeInfo
        employee={selectedEmployee}
        month={selectedMonth}
        year={selectedYear}
        visible={days.length > 0}
      />

      <Row style={{ margin: "25px 0 25px 0" }}>
        <Col>
          <DisplayTable
            days={days}
            updateDays={(dates) => {
              setDays(dates);
            }}
          />
        </Col>
      </Row>

      <Row style={{ margin: "15px 0 0 0" }}>
        <Col>
          <SummaryTable dates={days} />
        </Col>
      </Row>
    </Container>
  );
};

export default App;
