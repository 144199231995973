import React from "react";

const TotalTable = ({ dates }) => {
  const workingDays = dates.filter(
    (date) =>
      !date.holiday && !date.weekend && !date.vacation && !date.sickLeave
  ).length;

  const vacationDays = dates.filter((date) => date.vacation).length;
  const sickDays = dates.filter((date) => date.sickLeave).length;
  const holidaysDays = dates.filter(
    (date) => date.holiday && !date.weekend
  ).length;

  return (
    <>
      <tr>
        <td colSpan="7">
          <strong>SKUPAJ</strong>
        </td>
      </tr>
      <tr style={{ backgroundColor: "rgba(121,130,138,0.10)" }}>
        <td>
          <strong>Delo</strong>
        </td>
        <td>{workingDays} dni</td>
        <td>{workingDays * 8} ur</td>
        <td></td>
        <td>
          <strong>Mesec</strong>
        </td>
        <td>
          {dates.filter((date) => !date.holiday && !date.weekend).length} dni
        </td>
        <td>
          {dates.filter((date) => !date.holiday && !date.weekend).length * 8} ur
        </td>
      </tr>
      <tr style={{ backgroundColor: "rgba(121,130,138,0.10)" }}>
        <td>
          <strong>Dopust</strong>
        </td>
        <td>{vacationDays} dni</td>
        <td>{vacationDays * 8} ur</td>
        <td colSpan="4"></td>
      </tr>
      <tr style={{ backgroundColor: "rgba(121,130,138,0.10)" }}>
        <td>
          <strong>Bolniška</strong>
        </td>
        <td>{sickDays} dni</td>
        <td>{sickDays * 8} ur</td>
        <td colSpan="4"></td>
      </tr>
      <tr style={{ backgroundColor: "rgba(121,130,138,0.10)" }}>
        <td>
          <strong>Praznik</strong>
        </td>
        <td>{holidaysDays} dni</td>
        <td>{holidaysDays * 8} ur</td>
        <td colSpan="4"></td>
      </tr>
    </>
  );
};

export default TotalTable;
