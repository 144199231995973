import axios from "axios";

export const months = [
  { value: 0, label: "Januar" },
  { value: 1, label: "Februar" },
  { value: 2, label: "Marec" },
  { value: 3, label: "April" },
  { value: 4, label: "Maj" },
  { value: 5, label: "Junij" },
  { value: 6, label: "Julij" },
  { value: 7, label: "Avgust" },
  { value: 8, label: "September" },
  { value: 9, label: "Oktober" },
  { value: 10, label: "November" },
  { value: 11, label: "December" },
];

export const generateYears = (year) => {
  let years = [];
  year++;
  for (let i = year; i >= year - 10; i--) {
    years = [...years, { value: i, label: `${i}` }];
  }
  return years;
};

export const formatToTwoDigits = (input) => {
  return input.toLocaleString("sl-SI", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const formatHourMinuteString = (input) => {
  return `${formatToTwoDigits(input.hour)}:${formatToTwoDigits(
    input.minute
  )}:00`;
};

export const getHolidays = async (year) => {
  // https://date.nager.at/api/v2/PublicHolidays/1991/SI
  const holidays = await axios.get(`/.netlify/functions/holidays?year=${year}`);

  return holidays.data;
};

export const isWeekend = (date) => {
  const selectedDate = new Date(date);
  const dayOfWeek = selectedDate.getDay();
  return dayOfWeek === 6 || dayOfWeek === 0;
};

export const isHoliday = async (date, holidays) => {
  return holidays.some((holiday) => holiday.date === date);
};

export const generateDays = async (year, month) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const holidays = await getHolidays(year);
  let tempDays = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const dateAsString = `${year}-${formatToTwoDigits(
      month + 1
    )}-${formatToTwoDigits(day)}`;

    const workStartArray = [
      { hour: 7, minute: 0 },
      { hour: 7, minute: 30 },
      { hour: 8, minute: 0 },
      { hour: 8, minute: 30 },
    ];
    const randomStartHour = Math.floor(
      Math.random() * workStartArray.length + 1
    );
    const selectedStartHour = workStartArray[randomStartHour - 1];
    tempDays.push({
      date: dateAsString,
      weekend: isWeekend(dateAsString),
      holiday: await isHoliday(dateAsString, holidays),
      workStart: selectedStartHour,
      workEnd: {
        hour: selectedStartHour.hour + 8,
        minute: selectedStartHour.minute,
      },
      lunchStart: {
        hour: 12,
        minute: 0,
      },
      lunchEnd: { hour: 12, minute: 30 },
      vacation: false,
      sickLeave: false,
    });
  }

  return tempDays;
};
