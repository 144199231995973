import React from "react";
import { Row, Col } from "react-bootstrap";

const EmployeeInfo = ({ employee, month, year, visible }) => {
  if (!visible) return null;
  return (
    <Row className="display-on-print">
      <Col style={{ margin: "0 15px" }}>
        <p>
          Podjetje: <strong>APTO d.o.o</strong>
          <br />
          Delavec: <strong>{employee.label}</strong>
          <br />
          Obdobje:{" "}
          <strong>
            {month.label}, {year.label}
          </strong>
        </p>
      </Col>
    </Row>
  );
};

export default EmployeeInfo;
