import React from "react";
import { Table, Form, InputGroup } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";

const SummaryTable = ({ dates }) => {
  const [numberOfRoutes, setNumberOfRoutes] = React.useState("0");
  const [valueOfRoutes, setValueOfRoutes] = React.useState("0");
  const [otherCosts, setOtherCosts] = React.useState("0");
  const [workingDays, setWorkingDays] = React.useState(0);

  React.useEffect(() => {
    setWorkingDays(
      dates.filter(
        (date) =>
          !date.holiday && !date.weekend && !date.vacation && !date.sickLeave
      ).length
    );
  }, [dates]);

  if (dates.length <= 0) return null;

  return (
    <Table bordered hover>
      <thead>
        <tr style={{ backgroundColor: "rgba(121,130,138,0.10)" }}>
          <th>Stroški</th>
          <th>Število</th>
          <th>Vrednost</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Prehrana <small>(6,12 €)</small>
          </td>
          <td>{workingDays} dni</td>
          <td>
            <InputGroup>
              <CurrencyInput
                id="valueOfRoutes"
                name="valueOfRoutes"
                className="form-control "
                style={{ textAlign: "right" }}
                value={Number(workingDays * 6.12).toFixed(2)}
                decimalSeparator=","
                groupSeparator="."
                decimalsLimit={2}
                disabled
                allowNegativeValue={false}
              />

              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">€</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </td>
        </tr>
        <tr>
          <td>
            Prevoz <small>(službena pot)</small>
          </td>
          <td>
            <Form.Control
              type="number"
              value={numberOfRoutes}
              onChange={(e) => {
                setNumberOfRoutes(e.target.value || "0");
              }}
            />
          </td>
          <td>
            <InputGroup>
              <CurrencyInput
                id="valueOfRoutes"
                name="valueOfRoutes"
                className="form-control "
                style={{ textAlign: "right" }}
                value={valueOfRoutes}
                decimalSeparator=","
                groupSeparator="."
                decimalsLimit={2}
                onValueChange={(value, name) => setValueOfRoutes(value)}
                allowNegativeValue={false}
              />

              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">€</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </td>
        </tr>
        <tr>
          <td>Ostali stroški</td>
          <td style={{ backgroundColor: "rgba(121,130,138,0.10)" }}></td>
          <td colSpan="2">
            <InputGroup>
              <CurrencyInput
                id="otherCosts"
                name="otherCosts"
                className="form-control "
                style={{ textAlign: "right" }}
                value={otherCosts}
                decimalSeparator=","
                groupSeparator="."
                decimalsLimit={2}
                onValueChange={(value, name) => setOtherCosts(value)}
                allowNegativeValue={false}
              />

              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">€</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </td>
        </tr>
        <tr style={{ backgroundColor: "rgba(121,130,138,0.10)" }}>
          <td>
            <strong>SKUPAJ</strong>
          </td>
          <td style={{ backgroundColor: "rgba(121,130,138,0.10)" }}></td>
          <td style={{ textAlign: "right", paddingRight: "20px" }}>
            <strong>
              {new Intl.NumberFormat("sl-SI", {
                style: "currency",
                currency: "EUR",
              }).format(
                workingDays * 6.12 +
                  Number(
                    (valueOfRoutes || "0").replace(".", "").replace(",", ".")
                  ) +
                  Number((otherCosts || "0").replace(".", "").replace(",", "."))
              )}
            </strong>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default SummaryTable;
